@import '~@angular/material/theming';

$color-palette: (50 : #fceae5,
  100 : #f8cbbe,
  200 : #f3a992,
  300 : #ee8666,
  400 : #eb6c46,
  500 : #e75225,
  600 : #e44b21,
  700 : #e0411b,
  800 : #dd3816,
  900 : #d7280d,
  A100 : #ffffff,
  A200 : #ffd4cf,
  A400 : #ffa79c,
  A700 : #ff9083,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ededed,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #444,
    A200 : #454545,
    A400 : #000000,
    A700 : #ffffff,
  ));

$custom-typography: mat-typography-config($font-family: '"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;',
  $headline: mat-typography-level(36px, 48px, 500),
  $title: mat-typography-level(30px, 30px, 300),
  $body-1: mat-typography-level(14px, 24px, 300));

@include mat-core($custom-typography);

$primary: mat-palette($color-palette);
$accent: mat-palette($mat-amber, A200, A100, A400);
$warn: mat-palette($mat-deep-orange);

$dataplatform-custom-theme: mat-light-theme($primary, $accent, $warn);

.mat-card {
  background: #f4f4f4
}

a {
  text-decoration: none;
  color: mat-color($color-palette, 500)
}

.app-background {
  background-color: #FCFCFC !important;
}

.color-primary {
  color: mat-color($color-palette, 500);
}

.text-light-background {
  background-color: mat-color($color-palette, 100);
}


.hover-element:hover {
  background-color: mat-color($color-palette, 200) !important;
  cursor: pointer;
}

.titles-labels {
  color: #000000;
  margin-bottom: 8px;
  // font-family: "Roboto, Arial";
  font-size: 1.4em;
  font-weight: 400;
}

.card-text {
  // font-family: "Roboto, Arial";
  font-size: 14px;
  font-weight: 500;
}

.policy-custom {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f4f4f4;
  border: 1px solid #e3e2e2;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.searchIcon {
  color: #fff;
  background-color: mat-color($color-palette, 500);
  border-radius: 0 6px 6px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dataset-detail-min-height {
  min-height: 700px !important;
}

em {
  color: mat-color($color-palette, 500);
  font-style: inherit;
}

.mat-headline {
  font-size: 2.5em;
  font-weight: 300;
  color: #444;
}


.mat-h2 {
  color: mat-color($color-palette, 500);
}

.mat-h3 {
  color: mat-color($color-palette, 500);
  word-break: break-word;
}

.dataset-detail-min-height {
  min-height: 700px !important;
}

mat-grid-tile {
  background: #7fb82d;
  color: #ffffff;
}

mat-grid-tile:hover {
  background: #6fa326;
  cursor: pointer;
}

.footer {
  background: mat-contrast($color-palette, 600);
}

.customColorThemeGroups {
  $group-cards-color: mat-color($color-palette, 500);


  .mat-card {
    border-radius: 0;

  }

  @mixin darkenPrimary($percent) {
    background: darken($group-cards-color, $percent+ '%');
  }

  img {
    -webkit-filter: invert(1);
    filter: invert(1);
    max-height: 80px;
  }

  mat-card-title {
    color: white;
  }

  $prefix: background;

  //index, d=darkern, l-lighten, h-hue
  $font-list: (0 d 20,
    1 h 5,
    2 l 10,
    3 d 15,
    4 h -5,
    5 l 5,
    6 l 21,
    7 h 10,
    8 l 20,
    9 d 10,
    10 d 3,
    11 l 15,
    12 h 3,
    13 d 30,
    14 d 0,
    15 l 3,
    16 d 20,
    17 h 5,
    18 l 10,
    19 d 15,
    20 h -5,
    21 l 5,
    22 l 21,
    23 h 10,
    24 l 20,
    25 d 10,
    26 d 3,
    27 l 15,
    28 h 3,
    29 d 30,
    30 d 0
  );

  $color: $group-cards-color;

  @each $value in $font-list {
    .#{$prefix}-#{nth($value, 1)} {
      @if (nth($value, 2)=='d') {
        background-color: darken($color, nth($value, 3));
      }

      @else if (nth($value, 2)=='l') {
        background-color: lighten($color, nth($value, 3));
      }

      @else {
        background-color: adjust_hue($color, nth($value, 3));
      }
    }
  }
}

.img-responsive {
  overflow: hidden;
  width: 100%;
  height: auto;
}

.zoomOnHover {
  overflow: hidden;
}

.zoomOnHover:hover img {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.2);
}

.roundBorder {
  background-color: #e75225;
  color: #ffff;
  border-radius: 60px;
  display: inline-block;
  padding: 0px 5px 2px 5px; //4px 8px 6px 5px;
  text-decoration: none;
  border: 1px solid #e75225;

  .mat-icon {
    color: #e75225;
  }

  .roundBorderIcon {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #ffff;
    background: #ffff;
    padding: 5px;
    margin-right: -23px;
  }
}

.roundBorder:hover {
  background-color: #ffff;
  color: #e75225;

  .mat-icon {
    color: #ffff;
  }

  .roundBorderIcon {
    background: #e75225;
  }
}

.roundBorderIeCompat:hover {

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    /* IE10+ CSS styles */
    color: black;
  }
}