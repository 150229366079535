@import '~@angular/material/theming';

$color-palette: (
    50 : #e0eef5,
    100 : #b3d6e6,
    200 : #80bad5,
    300 : #4d9ec4,
    400 : #268ab7,
    500 : #0075aa,
    600 : #006da3,
    700 : #006299,
    800 : #005890,
    900 : #00457f,
    A100 : #add3ff,
    A200 : #7ab8ff,
    A400 : #479dff,
    A700 : #2d90ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
  ),
);

$custom-typography: mat-typography-config(
  $font-family: 'Open Sans , Helvetica Neue , Helvetica,Arial,sans-serif',
  $headline: mat-typography-level(36px, 48px, 500),
  $body-1: mat-typography-level(16px, 24px, 300)
);

$primary: mat-palette($color-palette);
$accent: mat-palette($mat-amber, A200, A100, A400);
$warn: mat-palette($mat-deep-orange);

$leeuwarden-custom-theme: mat-light-theme($primary, $accent, $warn);

.mat-card {
  background: #f4f4f4
}

a {
  text-decoration: none;
  color: mat-color($color-palette, 500)
}

.app-background {
  background-color: #FCFCFC !important;
}

.color-primary {
  color: mat-color($color-palette, 500);
}

.text-light-background {
  background-color: mat-color($color-palette, 100);
}


.hover-element:hover {
  background-color: mat-color($color-palette, 200) !important;
  cursor: pointer;
}

.titles-labels {
   color: #000000;
   margin-bottom: 8px;
   font-family: Roboto, "Helvetica Neue", sans-serif;
   font-size: 1.4em;
   font-weight: 400;
}
.card-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500 ;
}

.policy-custom {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f4f4f4;
  border: 1px solid #e3e2e2;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
}

.searchIcon {
  color: #fff;
  background-color: mat-color($color-palette, 500);  
  border-radius: 0 6px 6px 0;
  display: flex;
  align-items: center;
  cursor: pointer;  
}

.customColorThemeGroups {
  $group-cards-color: mat-color($color-palette, 500);

.mat-card {
  border-radius: 0;

}

@mixin darkenPrimary($percent) {
  //background: darken($group-cards-color, $percent+ '%');
}

img {
  -webkit-filter: invert(1);
  filter: invert(1);
  max-height: 80px;
}

mat-card-title {
  color: white;
}

$prefix: background;

//index, d=darkern, l-lighten, h-hue
$font-list: (
  0 d 20,
  1 h 5,
  2 l 10,
  3 d 15,
  4 h -5,
  5 l 5,
  6 l 21,
  7 h 10,
  8 l 20,
  9 d 10,
  10 d 3,
  11 l 15,
  12 h 3,
  13 d 30,
  14 d 0,
  15 l 3,
  16 d 20,
  17 h 5,
  18 l 10,
  19 d 15,
);

$color: $group-cards-color;

  @each $value in $font-list {
    .#{$prefix}-#{nth($value, 1)} {
      @if (nth($value, 2) == 'd') {
        background-color: darken($color, nth($value, 3));
      } @else if (nth($value, 2) == 'l') {
        background-color: lighten($color, nth($value, 3));
      } @else {
        background-color: adjust_hue($color, nth($value, 3));
      }
    }
  }
}

.banner-image {
  width: 100%;
  height: auto;
  border-radius: 6px;
}