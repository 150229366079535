@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles */
  .ieCustomFlex {
    flex-basis: auto !important;
    max-width: 39.3% !important;
  }
  .ieCustomMinWidth {
    min-width: 1% !important;
  }
}

.almere-theme {
  @import "./organizations/almere/theme.scss";
  @include angular-material-theme($almere-custom-theme);
}

.amersfoort-theme {
  @import "./organizations/amersfoort/theme.scss";
  @include angular-material-theme($amersfoort-custom-theme);
}

.catalogus-lelystad-theme {
  @import "./organizations/catalogus-lelystad/theme.scss";
  @include angular-material-theme($catalogus-lelystad-custom-theme);
}

.dataplatform-theme {
  @import "./organizations/dataplatform/theme.scss";
  @include angular-material-theme($dataplatform-custom-theme);
}

.denhaag-theme {
  @import "./organizations/denhaag/theme.scss";
  @include angular-material-theme($denhaag-custom-theme);
}

.exposome-theme {
  @import "./organizations/exposome/theme.scss";
  @include angular-material-theme($exposome-custom-theme);
}

.friesland-theme {
  @import "./organizations/friesland/theme.scss";
  @include angular-material-theme($friesland-custom-theme);
}

.greeninnovationhub-theme {
  @import "./organizations/greeninnovationhub/theme.scss";
  @include angular-material-theme($greeninnovationhub-custom-theme);
}

.health-ri-theme {
  @import "./organizations/health-ri/theme.scss";
  @include angular-material-theme($health-ri-custom-theme);
}

.iot-stadslab-s-hertogenbosch-theme {
  @import "./organizations/iot-stadslab-s-hertogenbosch/theme.scss";
  @include angular-material-theme($iot-stadslab-custom-theme);
}

.leeuwarden-theme {
  @import "./organizations/leeuwarden/theme.scss";
  @include angular-material-theme($leeuwarden-custom-theme);
}

.lelystad-theme {
  @import "./organizations/lelystad/theme.scss";
  @include angular-material-theme($lelystad-custom-theme);
}

.lidingo-theme {
  @import "./organizations/lidingo/theme.scss";
  @include angular-material-theme($lidingo-custom-theme);
}

.malmo-theme {
  @import "./organizations/malmo/theme.scss";
  @include angular-material-theme($malmo-custom-theme);
}

.nieuwegein-theme {
  @import "./organizations/nieuwegein/theme.scss";
  @include angular-material-theme($nieuwegein-custom-theme);
}

.provincie-noord-holland-theme {
  @import "./organizations/provincie-noord-holland/theme.scss";
  @include angular-material-theme($provincie-noord-holland-custom-theme);
}

.rotterdam-theme {
  @import "./organizations/rotterdam/theme.scss";
  @include angular-material-theme($rotterdam-custom-theme);
}

.sandbox-theme {
  @import "./organizations/sandbox/theme.scss";
  @include angular-material-theme($sandbox-custom-theme);
}

.schiedam-theme {
  @import "./organizations/schiedam/theme.scss";
  @include angular-material-theme($schiedam-custom-theme);
}

.scorewater-theme{
  @import "./organizations/scorewater/theme.scss";
  @include angular-material-theme($scorewater-custom-theme);
}

.s-hertogenbosch-theme {
    @import "./organizations/s-hertogenbosch/theme.scss";
    @include angular-material-theme($s-hertogenbosch-custom-theme);
}

.snuffelfiets-theme {
  @import "./organizations/snuffelfiets/theme.scss";
  @include angular-material-theme($snuffelfiets-custom-theme)
}

.stichtsevecht-theme {
  @import "./organizations/stichtsevecht/theme.scss";
  @include angular-material-theme($stichtsevecht-custom-theme);
}

.unbranded-theme {
  @import "./organizations/unbranded/theme.scss";
  @include angular-material-theme($unbranded-custom-theme);
}

.zaanstad-theme {
  @import "./organizations/zaanstad/theme.scss";
  @include angular-material-theme($zaanstad-custom-theme);
}
