@import '~@angular/material/theming';

$color-palette: (
  50 : #e1f5ff,
  100 : #b4e5f2,
  200 : #82d5f2,
  300 : #50c4fc,
  400 : #2cb7fc,
  500 : #11aafa,
  600 : #129ceb,
  700 : #1284d6,
  800 : #1178c2,
  900 : #0f58a0,
  A100 : #fcfdff,
  A200 : #c9d9ff,
  A400 : #96b6ff,
  A700 : #7da4ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$custom-typography: mat-typography-config(
  $font-family: '"Nunito","Source Sans Pro",Calibri,Candara,Arial,sans-serif;',
  $headline: mat-typography-level(36px, 48px, 300),
  $body-1: mat-typography-level(15px, 24px, 300)
);

$primary: mat-palette($color-palette, 900);
$accent: mat-palette($mat-amber, A200, A100, A400);
$warn: mat-palette($mat-deep-orange);

$scorewater-custom-theme: mat-light-theme($primary, $accent, $warn);

.mat-card {
  background: #f4f4f4;
  font-family: "Exo", Roboto, "Helvetica Neue", sans-serif;
}

.mat-button{
  font-family: "Exo", Roboto, "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
  color: mat-color($color-palette, 900)
}

.app-background {
  background-color: #FCFCFC !important;
}

.color-primary {
  color: mat-color($color-palette, 900);
}

.text-light-background {
  background-color: mat-color($color-palette, 100);
}


.hover-element:hover {
  background-color: mat-color($color-palette, 200) !important;
  cursor: pointer;
}

.titles-labels {
   color: #000000;
   margin-bottom: 8px;
   font-family: "Titan one","Source Sans Pro",Calibri,Candara,Arial,sans-serif;
   font-size: 1.4em;
   font-weight: 400;
}
.card-text {
  font-family: "Nunito","Source Sans Pro",Calibri,Candara,Arial,sans-serif;
  font-size: 14px;
  font-weight: 900 ;
}

.policy-custom {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f4f4f4;
  border: 1px solid #e3e2e2;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
}

.searchIcon {
  color: #fff;
  background-color: mat-color($color-palette, 900);  
  border-radius: 0 6px 6px 0;
  display: flex;
  align-items: center;
  cursor: pointer;  
}

.text-color {
  color: #333;
}

p {
  color: #333;
  font-family: 'Nunito', Helvetica Neue , Helvetica, Arial, sans-serif;
}

.mat-body {
  font-family: 'Nunito', Helvetica Neue , Helvetica, Arial, sans-serif;
}

.mat-headline {
  font-family: 'Titan One', "Source Sans Pro",Calibri,Candara,Arial,sans-serif;
  font-weight:300;
  color: #333
}

h1 {
  color: #000000;
  margin-bottom: 8px;
  font-family: 'Titan One',"Source Sans Pro",Calibri,Candara,Arial,sans-serif;
  font-size: 1.4em;
  font-weight: 300;
}

h2 {
  font-size: 32px;
}

.customColorThemeGroups {
  $group-cards-color: mat-color($color-palette, 900);

.mat-card {
  border-radius: 0;

}

@mixin darkenPrimary($percent) {
  background: darken($group-cards-color, $percent+ '%');
}

img {
  -webkit-filter: invert(1);
  filter: invert(1);
  max-height: 80px;
}

mat-card-title {
  color: white;
}

.active-button {
  background-color: rgba(0, 0, 0, 0.2);
}

$prefix: background;

//index, d=darkern, l-lighten, h-hue
$font-list: (
  0 d 20,
  1 h 5,
  2 l 10,
  3 d 15,
  4 h -5,
  5 l 5,
  6 l 21,
  7 h 10,
  8 l 20,
  9 d 10,
  10 d 3,
  11 l 15,
  12 h 3,
  13 d 30,
  14 d 0,
  15 l 3,
);

$color: $group-cards-color;

  @each $value in $font-list {
    .#{$prefix}-#{nth($value, 1)} {
      @if (nth($value, 2) == 'd') {
        background-color: darken($color, nth($value, 3));
      } @else if (nth($value, 2) == 'l') {
        background-color: lighten($color, nth($value, 3));
      } @else {
        background-color: adjust_hue($color, nth($value, 3));
      }
    }
  }
}

.app-header-toolbar{
  margin-bottom: 100px;
}

.banner-image {
  width: 100%;
  height: auto;
  border-radius: 6px;
}