@import '~@angular/material/theming';

$color-palette: (
  50 : #e9e9e9,
  100 : #c7c7c7,
  200 : #a2a2a2,
  300 : #7c7c7c,
  400 : #606060,
  500 : #444444,
  600 : #3e3e3e,
  700 : #353535,
  800 : #2d2d2d,
  900 : #1f1f1f,
  A100 : #f07a7a,
  A200 : #eb4c4c,
  A400 : #ff0505,
  A700 : #eb0000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$custom-typography: mat-typography-config(
  $font-family: 'Open Sans , Helvetica Neue , Helvetica,Arial,sans-serif',
  $headline: mat-typography-level(36px, 48px, 500),
  $body-1: mat-typography-level(16px, 24px, 300)
);

@include mat-core($custom-typography);

$primary: mat-palette($color-palette);
$accent: mat-palette($mat-amber, A200, A100, A400);
$warn: mat-palette($mat-deep-orange);

$zaanstad-custom-theme: mat-light-theme($primary, $accent, $warn);

.mat-card {
  background: #f4f4f4
}

a {
  text-decoration: none;
  color: #444444;
}

.app-background {
  background-color: #FCFCFC !important;
}

.color-primary {
  color: mat-color($color-palette, 500);
}

.text-light-background {
  background-color: mat-color($color-palette, 100);
}


.hover-element:hover {
  background-color: mat-color($color-palette, 200) !important;
  cursor: pointer;
}

.titles-labels {
   color: #000000;
   margin-bottom: 8px;
   font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size: 1.4em;
   font-weight: 400;
}
.card-text {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500 ;
}

.policy-custom {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f4f4f4;
  border: 1px solid #e3e2e2;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
}

.searchIcon {
  color: #fff;
  background-color: #00738c; 
  border-radius: 0 6px 6px 0;
  display: flex;
  align-items: center;
  cursor: pointer;  
}

.dataset-detail-min-height {
  min-height: 700px !important;
}

.mat-toolbar.mat-primary{
  background: #00738c !important;
}

.customColorThemeGroups {
  $group-cards-color: #70be43;

.mat-card {
  border-radius: 0;

}

@mixin darkenPrimary($percent) {
  background: darken($group-cards-color, $percent+ '%');
}

img {
  -webkit-filter: invert(1);
  filter: invert(1);
  max-height: 80px;
}

mat-card-title {
  color: white;
}

$prefix: background;

//index, d=darkern, l-lighten, h-hue
$font-list: (
  0 d #70be43,
  1 h #6bc29d,
  2 l #5faa4e,
  3 d #64b49d,
  4 h #4c8847,
  5 l #549685,
  6 l #407037,
  7 h #427363,
  8 l #64b49d,
  9 d #5faa4e,
  10 d #6bc29d,
  11 l #70be43,
  12 h #427363,
  13 d #407037,
  14 d #549685,
  15 l #4c8847,
);

$color: $group-cards-color;
  @each $value in $font-list {
    .#{$prefix}-#{nth($value, 1)} {
      background-color:  nth($value, 3);
    }
  }
}

.banner-image {
  width: 100%;
  border-radius: 6px;
}