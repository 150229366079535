/* You can add global styles to this file, and also import other style files */
@import "~basscss/css/basscss.css";
@import '~@angular/material/theming';

/* https://swiperjs.com/ */
@import "~swiper/css";
@import "~swiper/css/pagination";
@import "~swiper/css/navigation";

@include mat-core();

$custom-typography: mat-typography-config(
    $font-family: 'Helvetica Neue , Helvetica, Arial, sans-serif',
    $headline: mat-typography-level(35px, 48px, 500),
    $subheading-1: mat-typography-level(25px, 24px, 400),
    $body-1: mat-typography-level(14px, 24px, 300)
);


@include mat-base-typography($custom-typography);


:root {
    --swiper-theme-color: white; //for swiperjs
    // --swiper-pagination-color: red;
    --swiper-pagination-bullet-inactive-opacity: 0.8;
}

body {
    margin: 0;
    width: 100%;
    background-color: #FCFCFC;
}

.rating:focus {
    outline: none !important;
}

.center-flex {
    display: flex;
    justify-content: center;
}

.flex-wrap{
    flex-wrap: wrap;
}


.footer {

    margin-top: 5%;

    .footer-row {
        height: 100%;
        font-size: 0.8em;
        background-color: #ededed;
        padding-top: 35px;
        padding-bottom: 35px;
    }
}

.footer-row .p {
    font-weight: 300;
}

.mat-subheading-1,
.mat-headline,
h2 {
    color: #444
}

p {
    color: #444;
}

markdown {
    word-break: normal;
}

ul {
    margin: 0;
}

// ********************************************
// BREADCRUMB STYLING BEGIN
// ********************************************

.breadcrumbs__container {
    display: flex;
    margin-top: 0;
    list-style: none;

    @media (max-width: 1024px) {
    display: inline-block;
    word-break: break-word;
    white-space: normal;
    }
}

.breadcrumbs__item {
    
    li::after {
        content: ' \00a0';
        list-style-type: none
    }
    
    margin-top: 0;
    display: flex;
    list-style: none;
    -webkit-padding-start: 0;
    
}


.breadcrumbs__item--active {
    color: gray;
}


// ********************************************
// BREADCRUMB STYLING END
// ********************************************

.mat-tab-body-wrapper {
    margin-top: 2em;
}

.rightAlignedTabs .mat-tab-labels {
    justify-content: flex-end;
    /* align items in Main Axis */
}

td.mat-cell {
    width: 100px;
    word-break: break-word;
}

.noMarginHeader .mat-card-header-text {
    margin: 0 !important;
}

.noPaddingExpansionPanel .mat-expansion-panel-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.searchIconFix .mat-form-field-suffix {
    display: flex;
    position: absolute;
    top: .25em;
    right: 0;
    bottom: 0;
}

.disabledMoreContrast {
    .mat-select-disabled .mat-select-value,
    .mat-input-element:disabled {
        color: rgba(0, 0, 0, .60) !important;
        cursor: not-allowed;
    }
}

/*
// ********************************************
// ACCESIBILITY
// ********************************************
*/
a:focus-visible {
    outline-width:2px;
    outline-style: dashed;
    outline-color: gray;
  }

button:focus-visible {
    outline-width: 2px;
    outline-style: dashed;
    outline-color: gray;
  }

li:focus-visible {
    outline-width: 2px;
    outline-style: dashed;
    outline-color: gray;
  }


// .mat-expansion-panel:hover  .mat-expansion-indicator::after,
// .mat-expansion-panel:focus-visible  .mat-expansion-indicator::after{
//     outline-width: 2px;
//     outline-style: dashed;
//     outline-color: gray;
//   }

  // h1 headers are mandatory for every page for accessibility reasons.
  // Not every page want to have a title visible. This class hides that title.
  .accessibility-hidden-title{
    visibility: hidden;
    height:1px;
    width:1px;
    position:absolute;
  }
